/*
Responsive CSS
============================*/
@media only screen and (max-width: 767px) {

    .pt-100 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .default-btn {
        font-size: 15px;
        padding: 10px 20px;
    }
    .section-title {
        margin-bottom: 28px;

        h2 {
            font-size: 30px;
        }
    }
    .navbar-light {
        .navbar-nav {
            margin-top: 14px;

            .nav-item {
                margin-bottom: 10px;

                a {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        .others-option {
            .option-item {
                .default-btn {
                    padding: 10px 20px;
                    font-size: 14px;
                }
            }
        }
    }
    .main-banner {
        height: 100%;
        padding-top: 40px;
        padding-bottom: 80px;

        .banner-content {
            text-align: center;

            h1 {
                font-size: 30px;
            } 
            .banner-holder {
                margin-top: 20px;

                img {
                    max-width: 45%;
                }
            } 
        }
        .banner-image {
            margin-top: 50px;
        }
        .banner-form {
            padding: 20px;
            margin-top: 30px;
        }
    }  
    .default-shape {
        .shape-1 {
            display: none;
        }
        .shape-2 {
            display: none;
        }
        .shape-3 {
            display: none;
        }
        .shape-4 {
            display: none;
        }
        .shape-5 {
            display: none;
        }
    }
    .single-fun-fact {
        .icon {
            i {
                font-size: 40px;
            }
        }
        h3 {
            font-size: 30px;
        }
    }
    .about-content {
        h3 {
            font-size: 24px;
        }
        .about-btn {
            margin-top: 20px;
        }
    }
    .about-image {
        margin-top: 30px;
    }
    .single-features {
        h3 {
            font-size: 22px;
        }
    }
    .testimonials-slides {
        .client-feedback {
            .single-feedback {
                margin-bottom: 20px;

                p {
                    max-width: 100%;
                    font-size: 16px;
                }
                .icon {
                    i {
                        font-size: 40px;
                        margin-bottom: 14px;
                    }  
                }              
            }
        }
        .next-arrow, .prev-arrow {
            opacity: 1;
            visibility: visible;
        }
    }
    .overview-content {
        h3 {
            font-size: 24px;
        }
        .overview-btn {
            margin-top: 20px;
        }
    }
    .overview-image {
        margin-top: 30px;
    }
    .single-pricing-table {
        .pricing-header {
            h3 {
                font-size: 26px;
            }
        }
        .price {
            font-size: 35px;

            sup {
                top: -4px;
            }
        }
    }
    .faq-accordion-content .accordion .card .card-header button {
        padding: 18px 20px 18px 55px;
        font-size: 16px;
        text-align: left;
    }
    .faq-image {
        margin-top: 30px;
    }
    .single-team {
        .image {
            .content {
                h3 {
                    font-size: 22px;
                }
            }
        }
    }
    .app-download-content {
        h3 {
            font-size: 30px;
        }
    }
    .app-holder {
        img {
            max-width: 45%;
        }
    }
    .single-blog-post {
        .content {
            h3 {
                font-size: 22px;
            }
            .post-meta {
                li {
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
        }
    }
    .contact-info {
        padding-left: 0;
        
        .contact-info-content {
            margin-top: 30px;

            h3 {
                font-size: 20px;
            }
        }
    }
    .subscribe-content {
        h2 {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }
    .newsletter-form {
        .input-newsletter {
            height: 55px;
        }
        button {
            position: relative;
            right: 0;
            top: 0;
            height: 55px;
            margin-top: 20px;
        }
    }
    .pl-5, .px-5 {
        padding-left: 0 !important;
    }    
    .single-footer-widget {
        h3 {
            font-size: 20px;
            margin-bottom: 18px;
        }   
    } 
    .main-banner-two {
        height: 100%;
        padding-top: 70px;
        padding-bottom: 80px;

        .banner-content {
            h1 {
                font-size: 35px;
            }
            .banner-form {
                .input-newsletter {
                    height: 55px;
                }
                button {
                    position: relative;
                    right: 0;
                    top: 0;
                    height: 55px;
                    margin-top: 20px;
                }
            }
        }
    }
    .video-content {
        padding-top: 50px;
        padding-bottom: 50px;

        h3 {
            font-size: 25px;
        }
        .video-btn {
            width: 60px;
            height: 60px;
            line-height: 75px;
        }
    }
    .page-title-area {
        height: 220px;
    }
    .page-title-content {
        h2 {
            font-size: 30px;
        }
    }
    .pagination-area {
        .page-numbers {
            width: 35px;
        }
    }
    .blog-details-desc {
        .article-content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .comments-area {
        .comment-body {
            padding-left: 60px;
        }
        .comments-title {
            font-size: 22px;
        }
        .comment-author {
            .avatar {
                height: 50px;
                width: 50px;
                left: -65px;
            }
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 22px;
            }
        }
    }
    .widget-area {
        .widget {
            &:first-child {
                margin-top: 30px;
            }
        }
    }
    blockquote, .blockquote {
        padding: 25px !important;
    }    
    blockquote p, .blockquote p {
        font-size: 16px !important;
    }
    .testimonials-slides .client-thumbnails .item .title h3 {
        font-size: 20px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .pt-100 {
        padding-top: 80px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .ptb-100 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .default-btn {
        font-size: 15px;
        padding: 10px 20px;
    }
    .section-title {
        margin-bottom: 28px;
        h2 {
            font-size: 40px;
        }
    }
    .navbar-light {
        .navbar-nav {
            margin-top: 14px;

            .nav-item {
                margin-bottom: 10px;

                a {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        .others-option {
            .option-item {
                .default-btn {
                    padding: 10px 20px;
                    font-size: 14px;
                }
            }
        }
    }
    .main-banner {
        height: 100%;
        padding-top: 100px;
        padding-bottom: 100px;

        .banner-content {
            text-align: center;

            h1 {
                font-size: 50px;
            } 
            .banner-holder {
                margin-top: 20px;

                img {
                    max-width: 45%;
                }
            } 
        }
        .banner-image {
            margin-top: 50px;
        }
        .banner-form {
            margin-top: 30px;
        }        
    }  
    .single-fun-fact {
        .icon {
            i {
                font-size: 50px;
            }
        }
        h3 {
            font-size: 40px;
        }
    }
    .about-content {
        h3 {
            font-size: 34px;
        }
        .about-btn {
            margin-top: 20px;
        }
    }
    .about-image {
        margin-top: 30px;
    }
    .single-features {
        h3 {
            font-size: 25px;
        }
    }
    .testimonials-slides {
        .client-feedback {
            .single-feedback {
                margin-bottom: 20px;

                p {
                    max-width: 100%;
                    font-size: 20px;
                }
                .icon {
                    i {
                        font-size: 40px;
                        margin-bottom: 14px;
                    }  
                }              
            }
        }
        .next-arrow, .prev-arrow {
            opacity: 1;
            visibility: visible;
        }
    }
    .overview-content {
        h3 {
            font-size: 34px;
        }
        .overview-btn {
            margin-top: 20px;
        }
    }
    .overview-image {
        margin-top: 30px;
        text-align: center;
    }
    .single-pricing-table {
        .pricing-header {
            h3 {
                font-size: 35px;
            }
        }
        .price {
            font-size: 45px;

            sup {
                top: -4px;
            }
        }
    }
    .faq-image {
        margin-top: 30px;
        text-align: center;
    }
    .app-download-content {
        h3 {
            font-size: 40px;
        }
    }
    .app-holder {
        img {
            max-width: 45%;
        }
    }
    .single-blog-post {
        .content {
            h3 {
                font-size: 25px;
            }
            .post-meta {
                li {
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
        }
    }
    .contact-info {
        .contact-info-content {
            margin-top: 30px;

            h3 {
                font-size: 30px;
            }
        }
    }
    .subscribe-content {
        h2 {
            font-size: 35px;
            margin-bottom: 20px;
        }
    }
    .newsletter-form {
        .input-newsletter {
            height: 55px;
        }
        button {
            position: relative;
            right: 0;
            top: 0;
            height: 55px;
            margin-top: 20px;
        }
    }
    .pl-5, .px-5 {
        padding-left: 0 !important;
    }    
    .single-footer-widget {
        h3 {
            font-size: 25px;
            margin-bottom: 18px;
        }   
    } 
    .widget-area {
        .widget {
            &:first-child {
                margin-top: 30px;
            }
        }
    }

}

@media only screen and (min-width : 992px) and (max-width : 1199px) {

    .single-fun-fact {
        p {
            font-size: 16px;
        }    
    }
    .faq-accordion-content {
        .accordion {
            .accordion-title {
                font-size: 18px;
            }
        }
    }
    .single-blog-post {
        .content {
            .post-meta {
                li {
                    font-size: 14px;
                    margin-right: 10px;
                } 
            }
            h3 {
                font-size: 22px;
            }
        }
    }

}